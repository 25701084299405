import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background: #f1efe9;

  & input,
  & textarea,
  & select {
    outline: none;
    width: 100%;
    background: #fff;
    margin-bottom: 4%;
    border: 1px solid #ccc;
    padding: 3%;
    color: #555;
  }

  & textarea {
    flex: 1 0 auto;
    resize: none;
  }
`

const FormH1 = styled.h1`
  background: ${props => props.theme.colors.orange};
  padding: 20px 0;
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
  margin: -16px -16px 16px -16px;
`

const ContactButton = styled.button`
  width: 100%;
  padding: 3%;
  background: ${props => props.theme.colors.orange};
  border-bottom: 2px solid #f66a01;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  color: #fff;

  &:hover {
    background: #f66a01;
  }
`

const ContactForm = ({ intl }) => (
  <Form
    name="contact"
    method="post"
    data-netlify
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="contact" />
    <p style={{ display: 'none' }}>
      <label>
        Don’t fill this out if you're human: <input name="bot-field" />
      </label>
    </p>
    <FormH1>
      <FormattedMessage id="contactForm" />
    </FormH1>
    <input
      name="name"
      type="text"
      placeholder={intl.formatMessage({ id: 'namePlaceholder' })}
    />
    <input
      name="email"
      type="email"
      placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
    />
    <textarea
      name="enquiry"
      placeholder={intl.formatMessage({ id: 'enquiryPlaceholder' })}
    />
    <ContactButton>
      <FormattedMessage id="submit" />
    </ContactButton>
  </Form>
)

export default injectIntl(ContactForm)
