import React from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import styled from 'styled-components'
import media from 'styled-media-query'

const InfoContainer = styled.div`
  font-size: 0.875rem;

  ${media.lessThan('small')`
    font-size: 0.75rem;
  `}

  & h3 {
    margin-bottom: 10px;
  }

  & span {
    display: block;
    margin: 5px;
  }
`

export default withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 52.41443530500858, lng: 17.15487778186798 }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: 52.41443530500858, lng: 17.15487778186798 }}>
          <InfoWindow>
            <InfoContainer>
              <h3>
                <strong>Van Factory</strong>
              </h3>
              <span>ul. Długa 5</span>
              <span>62-021 Sarbinowo</span>
              <span>tel. 725-911-105</span>
              <a href="mailto:biuro@vanfactory.eu">
                <span>biuro@vanfactory.eu</span>
              </a>
            </InfoContainer>
          </InfoWindow>
        </Marker>
      )}
    </GoogleMap>
  ))
)
