import React from 'react'
import { injectIntl } from 'react-intl'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import media from 'styled-media-query'

import ContactForm from '../components/ContactForm'
import GoogleMap from '../components/GoogleMap'
import SEO from '../components/seo'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 0 auto;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}

  ${media.greaterThan('small')`
    width: 85%;
  `}
`

// TODO: figure out how dafaq these flex properties work in this case

// TODO: height temporarily set, because I can't figure out what's wrong
const MapContainer = styled.div`
  flex: 1 1 70%;
  margin: 20px;

  ${media.greaterThan('medium')`
    margin-right: 0;
  `}

  ${media.lessThan('medium')`
    flex: 1 0 70%;
  `}
`

const ContactContainer = styled.div`
  flex: 0 0 35%;
  margin: 0 20px 20px;

  ${media.lessThan('large')`
    flex: 0 0 50%;
  `}

  ${media.greaterThan('medium')`
    margin-top: 20px;
    margin-left: 0;
  `}
`

const MapElement = styled.div`
  height: 100%;
  width: 100%;
`

const MapLoadingElement = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    }} // TODO: remove inline style
  >
    <ClipLoader sizeUnit={'px'} size={150} color={'#fa7c1d'} loading={true} />
  </div>
)

const ContactPage = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'contact' })} />
    <Container>
      <GoogleMap
        isMarkerShown
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`}
        loadingElement={<MapLoadingElement />}
        containerElement={
          <MapContainer>
            <div />
          </MapContainer>
        }
        mapElement={<MapElement />}
      />
      <ContactContainer>
        <ContactForm />
      </ContactContainer>
    </Container>
  </>
)

export default injectIntl(ContactPage)
